<template>
  <div class="bannerBox">
    <div class="banner">
      <Swiper
        :listImg="$i18n.locale == 'zh' ? listImg : listImgEn"
      >
      </Swiper>
      <img src="@/assets/images/1.png" alt="" class="bgBottom" />
    </div>
  </div>
</template>
<script>
import Swiper from "./swiper.vue";
import ban1 from "@/assets/images/banner/1.jpg";
import ban2 from "@/assets/images/banner/2.jpg";
import ban3 from "@/assets/images/banner/5.jpg";
import ban1en from "@/assets/images/banner/1en.jpg";
import ban2en from "@/assets/images/banner/2en.jpg";
// import ban3en from "@/assets/images/banner/3en.jpg";
export default {
  name: "Banner",
  props: ["title"],
  components: {
    Swiper
  },
  data() {
    return {
      listImg: [
        {
          image: ban1,
          adr: "javascript:;"
        },
        {
          image: ban2,
          adr: "/juliang"
        },
        {
          image: ban3,
          adr: "https://haishen.star-media.cn/"
        }
      ],
      listImgEn: [
        {
          image: ban1en,
          adr: "javascript:;"
        },
        {
          image: ban2en,
          adr: "/juliang"
        },
        // {
        //   image: ban3en,
        //   adr: "http://www.xingchenjia.com/"
        // }
      ]
    };
  }
};
</script>

<style lang="scss">
.bannerBox {
  width: 100%;
  .banner {
    position: relative;
    img {
      width: 100%;
    }
    .bgBottom {
      position: absolute;
      bottom: 0;
      z-index: 99;
    }
  }
}
</style>
