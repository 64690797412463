<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <!-- 头部 -->
    <Header title="index"></Header>
    <Banner></Banner>
    <div class="indexBox">
      <!-- 数据 -->
      <!--<ul class="info">
        <li>
          <p>{{ $t("home.grid.num1") }}</p>
          <span>{{ $t("home.grid.brief1") }}</span>
          <img src="@/assets/images/n11.png" alt />
        </li>
        <li>
          <p>{{ $t("home.grid.num2") }}</p>
          <span>{{ $t("home.grid.brief2") }}</span>
          <img src="@/assets/images/22.png" alt />
        </li>
        <li>
          <p>{{ $t("home.grid.num3") }}</p>
          <span>{{ $t("home.grid.brief3") }}</span>
          <img src="@/assets/images/33.png" alt />
        </li>
        <li>
          <p>{{ $t("home.grid.num4") }}</p>
          <span>{{ $t("home.grid.brief4") }}</span>
          <img src="@/assets/images/44.png" alt />
        </li>
      </ul>-->
      <!-- 产品服务 -->
      <!-- <div class="productBox">
        <Title titleChina="产品服务" titleEng="PRODUCTS" class="wow slideInUp"></Title>
        <div class="contBox wow slideInUp">
          <div class="cont">
            <div class="title">{{ $t("home.products.subTit1") }}</div>
            <ul>
              <li>
                <router-link :to="{ name: 'zhijuyun' }">
                  <div class="innerBox">
                    <img src="@/assets/images/v2/home-zjy.png" alt="">
                    <p>{{ $t("home.products.subCon1.tit1") }}</p>
                    <span>{{ $t("home.products.subCon1.brief1") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'qianfanyun' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-qfy.png" alt />
                      <p>{{ $t("home.products.subCon1.tit2") }}</p>
                      <span>{{ $t("home.products.subCon1.brief2") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ylb' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-ylb.png" alt />
                      <p>{{ $t("home.products.subCon1.tit3") }}</p>
                      <span>{{ $t("home.products.subCon1.brief3") }}</span>
                    </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="cont">
            <div class="title">{{ $t("home.products.subTit2") }}</div>
            <ul>
              <li>
                <router-link :to="{ name: 'tianqin' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-tq.png" alt />
                      <p>{{ $t("home.products.subCon2.tit1") }}</p>
                      <span>{{ $t("home.products.subCon2.brief1") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'sheshou' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-ss.png" alt />
                      <p>{{ $t("home.products.subCon2.tit2") }}</p>
                      <span>{{ $t("home.products.subCon2.brief2") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'linyuan' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-ly.png" alt />
                      <p>{{ $t("home.products.subCon2.tit3") }}</p>
                      <span>{{ $t("home.products.subCon2.brief3") }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="cont">
            <div class="title">{{ $t("home.products.subTit5") }}</div>
            <ul>
              <li>
                <router-link :to="{ name: 'taitan' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-tt.png" alt />
                      <p>{{ $t("home.products.subCon5.tit1") }}</p>
                      <span>{{ $t("home.products.subCon5.brief1") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'xianhou' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-xh.png" alt />
                      <p>{{ $t("home.products.subCon5.tit2") }}</p>
                      <span>{{ $t("home.products.subCon5.brief2") }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="cont">
            <div class="title">{{ $t("home.products.subTit3") }}</div>
            <ul>
              <li>
                <router-link :to="{ name: 'qushouru' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-qsr.png" alt />
                      <p>{{ $t("home.products.subCon3.tit1") }}</p>
                      <span>{{ $t("home.products.subCon3.brief1") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'baolaidian' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-bld.png" alt />
                      <p>{{ $t("home.products.subCon3.tit2") }}</p>
                      <span>{{ $t("home.products.subCon3.brief2") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'xlaidian' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-xld.png" alt />
                      <p>{{ $t("home.products.subCon3.tit3") }}</p>
                      <span>{{ $t("home.products.subCon3.brief3") }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="cont">
            <div class="title">{{ $t("home.products.subTit4") }}</div>
            <ul>
               <li>
                <router-link :to="{ name: 'fyl' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-yx.png" alt />
                      <p>{{ $t("home.products.subCon4.tit3") }}</p>
                      <span>{{ $t("home.products.subCon4.brief3") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'juliang' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-jl.png" alt />
                      <p>{{ $t("home.products.subCon4.tit1") }}</p>
                      <span>{{ $t("home.products.subCon4.brief1") }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'cili' }">
                  <div class="innerBox">
                      <img src="@/assets/images/v2/home-cl.png" alt />
                      <p>{{ $t("home.products.subCon4.tit2") }}</p>
                      <span>{{ $t("home.products.subCon4.brief2") }}</span>
                    </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <!-- 合作伙伴 -->
      <div class="partnersBox">
        <Title titleChina="合作伙伴" titleEng="COOPERATION" class="wow slideInUp"></Title>
        <div class="partners wow slideInUp">
          <img src="@/assets/images/zijietiaodong.png" alt="" />
          <img src="@/assets/images/baidu.png" alt="" />
          <img src="@/assets/images/alibaba.png" alt="" />
          <img src="@/assets/images/tengxun.png" alt="" />
          <img src="@/assets/images/honor.png" alt="" />
          <img src="@/assets/images/vivo.png" alt="" />
          <img src="@/assets/images/huawei.png" alt="" />
          <img src="@/assets/images/jd.png" alt="" />
          <img src="@/assets/images/kuaishou.png" alt="" />
          <img src="@/assets/images/liebao.png" alt="" />
          <img src="@/assets/images/qutoutiao.png" alt="" />
          <img src="@/assets/images/renminwang.png" alt="" />
          <img src="@/assets/images/huanqiu.png" alt="" />
          <img src="@/assets/images/sina.png" alt="" />
          <img src="@/assets/images/yidian.png" alt="" />
          <img src="@/assets/images/pdd.png" alt="" />
          <img src="@/assets/images/maotai.png" alt="" />
          <img src="@/assets/images/huaxishengwu.png" alt="" />
          <img src="@/assets/images/lizhi.png" alt="" />
          <img src="@/assets/images/kouzijiao.png" alt="" />
          <img src="@/assets/images/taiping.png" alt="" />
          <img src="@/assets/images/xingbake.png" alt="" />
          <img src="@/assets/images/aiqiyi.png" alt="" />
          <img src="@/assets/images/58.png" alt="" />
          <img src="@/assets/images/xiecheng.png" alt="" />
          <img src="@/assets/images/changfazhan.png" alt="" />
          <img src="@/assets/images/ellipsis.png" alt="" class="ellipsis"/>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="contactUsBox">
        <Title titleChina="联系我们" titleEng="CONTACT" class="wow slideInUp"></Title>
        <ul class="wow slideInUp">
          <li>
            <img src="@/assets/images/mail.png" alt />
            <span>{{ $t("home.contact.tips1") }}</span>
            <p>{{ $t("home.contact.tips2") }}</p>
          </li>
          <li>
            <img src="@/assets/images/adress.png" alt />
            <span>{{ $t("home.contact.tips3") }}</span>
            <p>{{ $t("home.contact.tips4") }}</p>
          </li>
          <li>
            <img src="@/assets/images/number.png" alt />
            <span>{{ $t("home.contact.tips5") }}</span>
            <p>{{ $t("home.contact.tips6") }}</p>
          </li>
        </ul>
        <div class="buttonBox wow slideInUp">
          <div class="button">
            <router-link :to="{ name: 'contact' }">
              <span class="button__mask"></span>
              <span class="button__text">{{ $t("home.contact.conBtn") }}</span>
              <span class="button__text button__text--bis">{{ $t("home.contact.conBtn") }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import Banner from "./banner.vue";
import Title from "../../components/title.vue";
import Footer from "../../components/footer.vue";
export default {
  name: "home",
  components: {
    Header,
    Banner,
    Title,
    Footer
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,700,900");
$anim-text-offset: 1em;
.indexBox {
  width: 1300px;
  margin: 15px auto;
  .info {
    display: flex;
    justify-content: space-between;
    li {
      width: 258px;
      padding: 48px 0 46px 42px;
      height: 302px;
      background: #fafafa;
      color: rgba(34, 34, 34, 1);
      overflow: hidden;
      p {
        width: 217px;
        font-size: 40px;
        font-weight: 600;
        line-height: 67px;
      }
      span {
        display: inline-block;
        width: 217px;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
      }
      img {
        width: 130px;
        height: 130px;
        margin-top: 73px;
        margin-left: 170px;
        transition: height 0.4s, top 0.4s, margin-left 0.6s;
      }

      &:hover {
      img {
        width: 130px;
        height: 130px;
        margin-top: 73px;
        margin-left: 130px;
        transition: height 0.4s, top 0.4s, margin-left 0.6s;
      }
      
    }
    }
  }
  .productBox {
    .contBox {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      .cont {
        .title {
          height: 55px;
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
          line-height: 33px;
        }
         ul {
          padding: 12px 10px;
          display: flex;
          flex-direction: column;
           li {
            width: 230px;
            height: 134px;
            border-radius: 4px;
            clear: both;
            overflow: hidden;
            background: #fafafa;
             a {
              display: block;
              clear: both;
              overflow: hidden;
              width: 230px;
              height: 134px;

               .innerBox {
                width: 230px;
                height: 134px;
                display: flex;
                flex-direction: column;
                justify-items: center;
                align-items: center;

                  img {
                    width:40px;
                    height:40px;
                    margin:31px 0 5px 0;
                  }

                  p {
                    // font-weight: 600;
                    color: rgba(0, 0, 0, 1);
                    line-height: 22px;
                    margin-bottom: 6px;
                  }

                  span {
                    display: inline-block;
                    width: 167px;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(153, 153, 153, 1);
                    line-height: 24px;
                    display: none;
                    text-align: center;
                  }

                  &:hover {
                  width: 230px;
                  height: 134px;
                  display: flex;
                  flex-direction: column;
                  justify-items: center;
                  align-items: center;  
                  justify-content: center;
                  background: #0564cd;
                  img { display: none; }
                  p,span { color:#fff; }
                  span { display: block; }
                  transition-duration: 2s;
                }
               }
             }
           }
          li:nth-child(2) {
            margin: 12px 0;
          }
         }
      }
    }
  }
  .partnersBox {
    .partners {
      margin-top: 14px;
      img {
        margin-top: 63px;
        width:180px;
        height:59px;
        margin-right: 94px;
      }
      .ellipsis{
        width: 60px;
        height: 11px;
        vertical-align: top;
        margin-top: 73px;
        margin-left: 65px;
      }
      img:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  .contactUsBox {
    ul {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      li {
        width: 397px;
        height: 201px;
        padding: 88px 0 85px 0;
        background: #FAFAFA;
        text-align: center;
        img {
          width: 60px;
          height: 53px;
          display: block;
          margin: auto;
        }
        span {
          margin: 29px 0 12px 0;
          color: rgba(153, 153, 153, 1);
          line-height: 22px;
          font-size:16px;
          font-family:PingFangSC-Semibold,PingFang SC;
          font-weight:600;
          display: block;
        }
        p {
          width: 278px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(34, 34, 34, 1);
          line-height: 34px;
          margin: auto;
        }
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      margin: 56px 0 70px;
      a {
        color: rgba(34, 34, 34, 1);
      }
      .button {
        width: 180px;
        height: 60px;
        touch-callout: none;
        user-select: none;
        display: inline-block;
        border: 4px solid rgba(5, 100, 205, 1);
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &__text {
          display: block;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 60px;
          text-align: center;
          &:before {
            content: attr(title);
          }
          &--bis {
            display: block;
            line-height: 60px;
            text-align: center;
            position: absolute;
            font-size: 20px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateX(-1 * $anim-text-offset);
            opacity: 0;
          }
        }
        &__mask {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #0564cd;
          transform: translateX(-100%) rotate(45deg);
          transition: all 0.3s;
        }
      }
      .button:hover {
        opacity: 1;
        .button__text {
          animation: fx-text 0.3s ease-out;
          &--bis {
            animation: fx-text-bis 0.3s ease-out;
          }
        }
        .button__mask {
          animation: fx-mask 0.3s ease-out;
        }
      }
      .button:active {
        opacity: 1;
        background: white;
        color: inherit;
      }
    }
  }
}
@keyframes fx-mask {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

@keyframes fx-text {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX($anim-text-offset);
    opacity: 0;
  }
}
@keyframes fx-text-bis {
  0% {
    transform: translateX(-1 * $anim-text-offset);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
